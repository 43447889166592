<template>
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i :class="`pi pi-${shared ? 'globe' : 'lock'}`"></i>
    </span>
    <input-text :value="link" :readonly="true" />
    <Button
      v-if="shared"
      :icon="`pi pi-${iconCopy}`"
      :class="classIcon"
      @click="iconCopy === 'copy' ? copyCurrentLink(name) : ''"
    />
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
export default {
  name: 'ShowLink',
  components: {
    Button,
    InputText,
  },
  mixins: [setFocus],
  props: {
    link: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icons: {
      type: String,
      default: '',
    },
    shared: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconCopy: this.icons,
    };
  },
  computed: {
    classIcon() {
      return this.iconCopy === 'check' ? 'p-button-success' : 'p-button-outlined';
    },
  },
  methods: {
    copyCurrentLink(item) {
      const url = this.link;
      this.$clipboard(url);
      this.iconCopy = 'check';
      this.$emit('set-icon', { name: item, icon: 'check' });
      // }, 200);
      setTimeout(() => {
        this.iconCopy = 'copy';
        this.$emit('set-icon', { name: item, icon: 'copy' });
        this.$emit('set-icon', { name: item, icon: 'copy' });
      }, 1000);
    },
    onChange() {
      this.$emit(
        'change',
        this.newDescription.length > 254 ? this.newDescription.substr(0, 254) : this.newDescription
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  margin-bottom: -2rem;
  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
}
}
</style>
