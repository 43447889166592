<template>
  <div>
    <div class="folder">
      <!--          :class="error ? 'p-inputtext p-invalid' : 'p-inputtext'"-->
      <!--          aria-describedby="prefix-help"-->
      <!--          :placeholder="$t('balancer.title')"-->
      <!--          style="width: 100%"-->
      <!--          @input="onChange('folder', $event)"-->
      <input-text v-model="prefixWithName" disabled />
      <transition name="fade" mode="out-in">
        <div class="folder__content">
          <!--          <div class="standart-title">-->
          <!--            {{ prefix }}-->
          <!--          </div>-->
          <div class="field">
            <!--            <label for="prefix" class="standart-title">-->
            <!--              <span>{{ $t('balancer.name') }}</span>-->
            <!--            </label>-->
            <input-text
              v-model="name"
              id="prefix"
              :class="error ? 'p-inputtext p-invalid' : 'p-inputtext'"
              :placeholder="$t('balancer.title')"
              aria-describedby="prefix-help"
              style="width: 100%"
              @input="onChange('folder', $event)"
            />
            <!--            <small id="prefix-help">Enter your username to reset your password.</small>-->
            <small id="prefix-help" :class="error ? 'p-invalid' : 'standart-text'">{{
              error ? $t(error) : $t('balancer.hint')
            }}</small>

            <!--          <base-input-->
            <!--            v-model="name"-->
            <!--            type="text"-->
            <!--            :custom-error-messages="patternValidation"-->
            <!--            :name="$t('balancer.name')"-->
            <!--            :required="true"-->
            <!--            use-reactive-validation-->
            <!--            :label="$t('balancer.name')"-->
            <!--            :pattern="error ? 'true' : name"-->
            <!--            :hint="$t('balancer.hint')"-->
            <!--            class="folder__content-input"-->
            <!--            @input="onChange('folder', $event)"-->
            <!--          >-->
            <!--          </base-input>-->
            <!--          </div>-->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';

export default {
  name: 'NewFolder',
  components: {
    InputText,
  },
  mixins: [],
  props: {
    storageid: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '/',
    },
    storage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: '',
      selectFolder: '',
      // folders: [],
      error: '',
    };
  },
  computed: {
    // patternValidation() {
    // return {
    // customValidationMsgs: {
    // confirm: {
    //   patternMismatch: `${this.error ? this.$t(this.error) : null}`,
    // },
    // },
    // };
    // },
    // project() {
    //   return this.$store.getters['moduleStack/project_id'];
    // },
    foldersForCurrentPrefix() {
      return this.storage.objects.filter(x => x.isFolder && x.key !== this.prefix).length
        ? this.storage.objects
            .filter(x => x.isFolder && x.key !== this.prefix)
            .map(x => x.uniqueName)
        : '/';
    },
    prefixWithName() {
      return `${this.prefix}${this.name ? this.name + '/' : ''}`;
    },

    // objects() {
    // return this.$store.getters['moduleStack/getObjectsOfCurrentStorage'];
    // },
  },
  watch: {
    name: {
      handler: function (event) {
        if (event) {
          // console.log(event);
          const folder = this.prefix === '/' ? event : this.prefix + '/' + event;
          // console.log('name', folder);
          if (
            this.foldersForCurrentPrefix
              // .filter(t => t.type === 'object')
              // .map(x => x.name)
              .includes(folder)
          )
            this.error = 'include';
          else if (event.includes('/')) this.error = 'slash';
          else if (event.length < 1 || event.length > 63) this.error = 'length';
          else {
            this.error = '';
            this.$emit('change', `${this.prefix}${this.name}/`);
          }
        } else this.error = 'length';
      },
      immediate: true,
    },
    // selectFolder: {
    //   handler: function (event) {
    //     if (event) {
    //       // console.log('selectFolder', event);
    //       const folder = event.name === '/' ? this.name : event.name + '/' + this.name;
    //       // console.log(folder, 'selectFolder');
    //       if (
    //         this.folders
    //           .filter(t => t.type === 'object')
    //           .map(x => x.name)
    //           .includes(folder)
    //       )
    //         this.error = 'include';
    //       else if (this.name.length < 1 || this.name.length > 63) this.error = 'length';
    //       else this.error = '';
    //     }
    //   },
    // },
    error: {
      handler: function (event) {
        if (event) {
          // console.log(event);
          this.$emit('notready');
        }
      },
    },
  },
  async mounted() {
    // await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
    this.$emit('notready');
  },
  methods: {
    // nameFolder({ name }) {
    //   return name;
    // },
    onChange(name, event) {
      // console.log(`${this.selectFolder}/${event}`);
      // this.na
      let sendEmit;
      if (!!event.length) {
        if (!this.error && this.prefix === '/' && name === 'folder') {
          sendEmit = event + '/';
          this.$emit('change', sendEmit);
        } else if (!this.error && this.prefix !== '/' && name === 'folder' && event) {
          sendEmit = `${this.prefix}${event}/`;
          this.$emit('change', sendEmit);
        } else if (!this.error && this.prefix === '/' && name !== 'folder') {
          sendEmit = this.name + '/';
          this.$emit('change', sendEmit);
        } else if (!this.error && this.prefix !== '/' && name !== 'folder') {
          sendEmit = `${event}${this.name}/`;
          this.$emit('change', sendEmit);
        } else {
          // console.log(name, event);
          this.$emit('notready');
        }
      }

      // if (!this.error) {
      //   name === 'folder' ? this.$emit('change', event) : this.$emit('change', event);
      // }
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Новая папка:",
    "error": "Недопустимый IP-адрес",
    "used": "Папка с таким именем уже есть.",
    "slash": "Недопустимый символ -'/'.",
    "include": "Такой префикс уже существует.",
    "length": "Недопустимая длина имени перфикса.",
    "type": "Тип:",
    "label": "Выбрать папку:",
    "day": "День",
    "hour": "Час",
    "month": "Месяц",
    "subnet": "Подсеть",
    "attachTo": "Подключить",
    "newRule": "Добавить правило",
    "newBalancer": "Создать балансировщик",
    "addRule": "Добавить еще правило",
    "port": "IP балансировщика",
    "portAviable": "IP балансировщика",
    "public": "Подключить плавающий IP",
    "warnings": "Обращаем ваше внимание на то, что расходы формируются раз в сутки, а счёт выставляется за фактически отработанное время. Сами расчёты осуществляются каждые 5 минут. \n Представленные ниже цены рассчитаны исходя из того, что сервер проработает полный период: час, день, месяц (30 дней).",

    "nav": {
      "private": "Приватный",
      "public": "Публичный"
    },
    "additional": "Стоимость",
    "balancer" : {
      "name" : "Имя папки:",
      "title" : "префикс...",
      "hint" : "Имя префикса должно быть уникально в рамках хранилища. Длиной от 1 до 63 символов без '/'"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}

.folder {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &__content {
    display: flex;
    margin-top: 1rem;
    //justify-content: space-between;
    text-align: left;
    align-items: baseline;

    &-select {
      min-width: 15rem

    }
    &-input {
      margin-left: 1rem
      min-width: 20rem
      //width: 100%

    }


    &-item {
      min-width: 10rem;
      font-size: 20px;
      line-height: 26px;

      +breakpoint(sm-and-up) {
        min-width: 10rem;

      }
    }
  }
}
</style>
