<template>
  <div class="vps-detail">
    <transition name="slide-fade">
      <tariffs-table-pool :id="balancerid" :table-head="tableHead" />
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
// import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import TariffsTablePool from '@/layouts/Stack/pages/Main/components/TariffsTablePool';
import { OPTariff } from '@/models/OP/OPTariff';
export default {
  name: 'Rule',
  components: { TariffsTablePool },
  mixins: [providerChange],
  props: {
    balancerid: {
      type: String,
      default: '',
    },
    tariff: {
      type: OPTariff,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      isCurrentLoading: false,
      localDisk: false,
      stats: {},
      isServerError: false,
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '150px',
            maxWidth: '230px',
          },
          // sort: {
          //   prop: 'name',
          //   order: 'asc',
          // },
        },
        {
          key: 'port',
          label: this.$t('tableHead.port'),
          style: {
            width: '150px',
            maxWidth: '200px',
            textAlign: 'center',
          },
        },

        {
          key: 'ssl',
          // label: this.$t('tableHead.conditions'),
          style: {
            width: '44px',
          },
        },
        {
          key: 'member',
          // label: this.$t('tableHead.conditions'),
          style: {
            width: '44px',
            textAlign: 'center',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '54px',
          },
          // sort: {
          //   prop: 'operating_status',
          //   order: 'asc',
          // },
        },
        // {
        //   key: 'state',
        //   label: this.$t('tableHead.state'),
        //   style: {
        //     width: '54px',
        //   },
        //   sort: {
        //     prop: 'provisioning_status',
        //     order: 'asc',
        //   },
        // },
        {
          key: 'conditions',
          // label: this.$t('tableHead.conditions'),
          style: {
            width: '70px',
            maxWidth: '70px',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
            textAlign: 'center',
          },
        },
      ],
      isMobile: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.moduleStack.serverRequest;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
  },
  beforeRouteLeave(_to, _from, next) {
    this.status = {};
    next();
  },
  methods: {
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "tableHead": {
        "name": "Правило",
        "type": "Тип",
        "network": "Сеть",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
