<template>
  <div class="breadcrumbs">
    <plain-button color="primary" nowrap @click="!disabled ? $emit('storage-click') : ''">{{
      storage
    }}</plain-button>
    <!--    <plain-button icon="right-icon" size="minimal" />-->
    <template v-for="(item, index) in breadcrumbs">
      <div :key="index">
        <!--        <plain-button v-if="index !== breadcrumds.length" icon="right-icon" size="minimal" />-->
        <plain-button icon="right-icon" size="minimal" />
        <plain-button
          :color="index !== breadcrumbs.length - 1 && !disabled ? 'primary' : 'dim'"
          nowrap
          @click="
            index !== breadcrumbs.length - 1 && !disabled ? $emit('folder-click', links[index]) : ''
          "
          >{{ item }}</plain-button
        >
      </div>
      <!--        <tariff-card :tariff="vps" @click-more="$emit('click-more', vps)" />-->
    </template>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumdsStorage',
  components: {},
  mixins: [],
  props: {
    storage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    links() {
      const arrays = this.prefix.split('/').filter(x => x.length);
      const routes = [];
      arrays.forEach(x => {
        // console.log(x, this.prefix.split(x));
        // console.log(
        // this.prefix.split(x)[0] === ''
        routes.push(this.prefix.split(x)[0] + x + '/');
        // : this.prefix.split(x)[0] + x
        // );
        // console.log(x, x + '/' + this.prefix.split(x)[0], this.prefix.split(x));

        // console.log(x);
      });
      return routes;
    },
    breadcrumbs() {
      return this.prefix.split('/').filter(x => x.length);
      // console.log(this.prefix);

      // return arrays;
    },
  },
  watch: {},
  mounted() {},

  beforeDestroy() {},
  methods: {},
};
</script>

<i18n>
{
  "ru": {

  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.breadcrumbs {
 display: flex
}
</style>
