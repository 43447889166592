import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';
import CreateLink from '@/layouts/Stack/components/CreateLink';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import ChangeDescription from '@/layouts/Stack/components/ChangeDescription.vue';
import BaseProgress from '@/components/BaseLoader/BaseProgress.vue';

export default {
  mixins: [showErrorModal, modals],
  data() {
    return {
      data: {},
      nameObject: '',
      isProcessing: false,
    };
  },
  computed: {
    // isAuth() {
    //   return !!localStorage.getItem('token');
    // },
  },
  // watch: {
  //   isAuth: {
  //     handler: function (event) {
  //       console.log(event);
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    createS3Link: function (instance) {
      const that = this;
      this.nameObject = instance;
      return new Promise(() => {
        const selfName = 'createNewS3Link';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: CreateLink,
          closeOnBackdrop: false,
          on: {
            sum: data => {
              // console.log(this.isAuth);
              // if (!this.isAuth) {
              //   this.$modals.close();
              //   this.showResModal('Ваша сессия истекла, пожалуйста перелогиньтесь.');
              // }
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.create') },
              on: {
                click: () => {
                  this.getSignedUrl(instance)
                    .then(async data => {
                      // that.$modals.close();
                      Vue.set(this.modal, 'component', ChangeDescription);
                      Vue.set(this.modal, 'props', {
                        description: data,
                        disabled: true,
                        text: 'Адрес временной ссылки:',
                      });
                      Vue.set(this.modal, 'footer', false);
                      // Vue.set(this.modal, 'footer', true);
                      // Vue.set(this.modal.footer, 'centered', true);
                      Vue.set(this.modal, 'footer', {
                        // props: { title: 'Скопировать ссылку' },
                        centered: true,
                        confirm: {
                          // props: { title: this.$t('modal.sure.confirm') },
                          props: { title: 'Скопировать ссылку' },
                          on: {
                            click: () => {
                              this.$clipboard(data);
                              this.$modals.close();
                            },
                          },
                        },
                      });
                      // this.modal.component = ChangeDescription;
                      // this.showError(data, this.$t('sure.successLink'));
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async getSignedUrl(time) {
      const config = {
        region: 'ru-msk',
        endpoint: 'https://s3.rusonyxcloud.ru',
        credentials: {
          accessKeyId: this.s3key.access,
          secretAccessKey: this.s3key.secret,
        },
      };
      const client = await new S3Client(config);
      const input = {
        Bucket: this.storageid,
        Key: this.nameObject,
      };
      async function getSignedDownloadUrl() {
        let command = new GetObjectCommand(input);
        return await getSignedUrl(client, command, { expiresIn: time });
      }
      const url = await getSignedDownloadUrl();
      // this.$clipboard(url);
      return url;
    },
  },
};
