<template>
  <div class="vps-detail">
    <Toast position="top-right" />
    <transition name="slide-fade">
      <page-block-stack :title="' '" class="vps-detail-backup__block">
        <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
        <page-block v-else-if="!isRequest" style="margin-top: 0">
          <template #pagination>
            <breadcrumds-storage
              v-if="prefix.length > 1"
              :storage="storageid"
              :disabled="isDisabledButtons"
              :prefix="prefix"
              @folder-click="onFolderClick($event)"
              @storage-click="onStorageClick()"
            />
            <!--            <plain-button color="del" nowrap>-->
            <!--              {{ prefix }}-->
            <!--            </plain-button>-->
          </template>
          <!--            state-storage="local"-->
          <!--            :state-key="stateKey"-->
          <!--            @value-change="onValueChange($event)"-->
          <!--            v-model:selectionKeys="selectedObjects"-->
          <!--            :row-hover="true"-->
          <!--            :show-clear-button="true"-->
          <!--            :loading="loader"-->
          <!--            :loading="loader || uploadSelectedObjects"-->
          <!--            @row-unselect-all="onRowUnselectAll"-->
          <!--            @row-select="onRowSelect"-->
          <!--            @row-select-all="onRowSelectAll"-->
          <!--            @row-unselect="onRowUnselect"-->
          <DataTable
            :expanded-rows.sync="expandedRows"
            :loading="uploadSelectedObjects ? uploadSelectedObjects : loader"
            @row-expand="onRowExpand"
            @row-collapse="onRowCollapse"
            @page="onPage"
            data-key="id"
            :always-show-paginator="true"
            :value="objects"
            :selection.sync="selectedObjects"
            ref="objects"
            :paginator="true"
            :page-link-size="10"
            :first="firstPage"
            @sort="onSort"
            :show-gridlines="false"
            :rows-per-page-options="[10, 25, 50, 100]"
            removable-sort
            :filters.sync="filters"
            :global-filter-fields="['uniqueName', 'size', 'date']"
            class="p-datatable p-datatable-sm"
            current-page-report-template=" из {totalPages}"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageInput CurrentPageReport RowsPerPageDropdown "
            :rows="rowPage"
            selection-mode="'multiple'"
            responsive-layout="scroll"
          >
            <!--            :meta-key-selection="false"-->
            <!--            @value-change="onValueChange"-->
            <!--            @row-unselect="onRowUnselect"-->
            <!--            @row-select="onRowSelect"-->
            <!--            @row-click="onRowClick"-->
            <!--            @onRowSelect="selectAll($event)"-->
            <!--            @select-all="selectAll"-->
            <!--            @select-all-change="selectAll"-->
            <!--            :select-all="selectAll()"-->
            <template #header>
              <div class="filter">
                <div v-if="mode === 'view'" class="filter-upload">
                  <FileUpload
                    ref="upload"
                    mode="basic"
                    :disabled="isDisabledButtons"
                    :name="file && file.name ? file.name : null"
                    :multiple="false"
                    :auto="false"
                    :max-file-size="10 * 1024 ** 3"
                    choose-label="Добавить"
                    :custom-upload="true"
                    :show-cancel-button="true"
                    :invalid-file-size-message="
                      'Вы можете загрузить файл размером до 10ГБ.\n' +
                      'Файлы более 10ГБ можно загрузить через CLI или приложение для работы с S3.'
                    "
                    button-class="p-button-sm"
                    button-style="upload-button"
                    @progress="progressLoad($event)"
                    @select="select($event)"
                    @clear="clearFile($event)"
                    @before-upload="view($event)"
                    @uploader="myUploader($event)"
                  />
                  <Button
                    v-if="fileName && !loader"
                    type="button"
                    icon="pi pi-times"
                    class="p-button-danger p-button-sm filter-upload__btn"
                    @click="$refs.upload.clear()"
                  ></Button>
                  <plain-button
                    v-if="!fileName"
                    color="primary"
                    nowrap
                    class="filter-upload__btn"
                    @click="
                      !isDisabledButtons
                        ? newFolder({ storage: tariff, storageid: storageid, prefix: prefix })
                        : ''
                    "
                  >
                    <!--                    @click="newFolder({ storage: tariff, storageid: storageid, folders: folders })"-->
                    {{ $t('addFolder') }}
                  </plain-button>
                  <!--                  <Button-->
                  <!--                    v-if="!progress"-->
                  <!--                    type="button"-->
                  <!--                    :icon="mode === 'edit' ? 'pi pi-th-large' : 'pi pi-pencil'"-->
                  <!--                    :label="mode === 'edit' ? 'Режим просмотра' : 'Режим редактирования'"-->
                  <!--                    class="p-button-sm"-->
                  <!--                    @click="editMode"-->
                  <!--                  ></Button>-->
                </div>
                <div v-if="mode === 'edit'" class="filter-upload">
                  <!--                      v-if="fileName && !loader"-->
                  <Button
                    type="button"
                    icon="pi pi-times"
                    label="Очистить"
                    class="p-button-sm"
                    @click="clearSelectedObjects"
                  ></Button>
                  <div>
                    <div v-if="errorToDelete" class="filter-upload__btn">Выбрано много файлов</div>
                    <div v-else class="filter-upload__btn">
                      {{ $tc('object', countOfSelectedObjects) }}
                    </div>
                  </div>
                  <!--                    v-tooltip.right="'Одновременно можно удалить не более 1000 объектов'"-->
                  <Button
                    v-tooltip="countOfSelectedObjects > 1000 ? tooltipOptions : null"
                    type="button"
                    icon="pi pi-trash"
                    label="Удалить"
                    :disabled="countOfSelectedObjects > 1000 || errorToDelete"
                    class="p-button-danger p-button-sm filter-upload__btn"
                    @click="deleteObject(null, selectedObjectsForDelete)"
                  ></Button>
                  <!--                    @click="deleteSelectedObjects"-->
                  <!--                  <Button-->
                  <!--                    v-if="!progress"-->
                  <!--                    type="button"-->
                  <!--                    :icon="mode === 'edit' ? 'pi pi-th-large' : 'pi pi-pencil'"-->
                  <!--                    :label="mode === 'edit' ? 'Режим просмотра' : 'Режим редактирования'"-->
                  <!--                    class="p-button-sm"-->
                  <!--                    @click="editMode"-->
                  <!--                  ></Button>-->
                </div>
                <div v-if="progress && mode === 'view'" class="filter-progress">
                  <base-progress
                    v-if="progress"
                    :value="progress"
                    :text="' '"
                    type="indeterminate"
                  ></base-progress>
                </div>
                <div class="filter-search">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Поиск..." />
                    <Button
                      v-if="filters['global']?.value?.length > 0"
                      type="button"
                      :disabled="isDisabledButtons"
                      icon="pi pi-times"
                      class="p-button-danger p-button"
                      style="margin-left: 0.25rem"
                      @click="filters['global'].value = ''"
                    ></Button>
                  </span>
                </div>
              </div>
            </template>
            <template #empty> Нет объектов. </template>
            <template #loading> Идет загрузка информации... </template>
            <!--            <Column selection-mode="multiple">-->
            <Column>
              <!--            <Column selection-mode="multiple">-->
              <template #header>
                <!--                <div v-if="!slotProps.data.isFolder">-->
                <Checkbox
                  id="binary"
                  v-model="checkedAll"
                  :binary="true"
                  @click="selectAll(checkedAll)"
                />
                <!--                </div> -->
              </template>
              <template #body="slotProps">
                <!--                <div v-if="!slotProps.data.isFolder">-->
                <Checkbox
                  v-model="selectedObjects"
                  :value="slotProps.data"
                  @click="selectOne(isRowSelected(slotProps.data.key), slotProps.data)"
                />
                <!--                </div> -->
              </template>
            </Column>
            <!--            </Column>-->
            <Column
              filter-field="uniqueName"
              :exclude-global-filter="true"
              field="uniqueName"
              header="Имя"
              :styles="{ width: '65%' /*, 'padding-left': '80px'*/ }"
              :sortable="true"
            >
              <template #body="slotProps">
                <router-link
                  v-if="slotProps.data.isFolder && !isDisabledButtons"
                  :to="{ name: 'containerObjects', query: { prefix: slotProps.data.prefix } }"
                  class="folder-link medium-text"
                >
                  <!--                  <i class="pi pi-folder-open" />-->
                  <i :class="getTypeIconForPrefix(slotProps.data.key)" />
                  {{ slotProps.data.uniqueName }}
                  <!--                  {{ getTypeIconForPrefix(slotProps.data.key) }}-->
                  {{
                    isRowSelected(slotProps.data.key) && countObjectForPrefix(slotProps.data.key)
                      ? $tc('objects', countObjectForPrefix(slotProps.data.key))
                      : null
                  }}
                  <!--                  {{ slotProps.data?.selected ? '44444444444444444' : '' }}-->
                </router-link>
                <div
                  v-else-if="slotProps.data.isFolder && isDisabledButtons"
                  class="folder-link medium-text"
                >
                  <i class="pi pi-folder-open" />
                  {{ slotProps.data.uniqueName }}
                </div>
                <label v-else class="standart-text">
                  <i class="pi pi-file" />
                  {{ slotProps.data.uniqueName }}
                </label>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по имени"
                />
              </template>
            </Column>
            <Column
              field="size"
              header="Размер"
              :styles="{ width: '10%', height: '60px' }"
              :sortable="true"
            >
              <template #body="slotProps">
                <div v-if="slotProps.data.isFolder">
                  <loader-prefix-size
                    :prefix="slotProps.data.prefix"
                    :storage="tariff"
                    ref="prefixSize"
                    :enabled="selectedObjects.length > 0 ? true : enabledPrefixLoader"
                    @disable-loader="disablePrefixLoader($event)"
                    @loader-prefix="loadPrefixSize($event)"
                  />
                </div>
                <div v-else>
                  {{ size(slotProps.data) }}
                </div>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по размеру"
                />
              </template>
            </Column>
            <Column field="date" header="Дата" :styles="{ width: '15%' }" :sortable="true">
              <template #body="slotProps">
                {{ created(slotProps.data) }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по дате"
                />
              </template>
            </Column>
            <Column :expander="!selectedObjects.length > 0" :header-style="{ width: '4%' }" />
            <template #expansion="slotProps">
              <div :class="slotProps.data.isFolder ? 'container-folder' : 'container-file'">
                <div class="container-row">
                  <div v-if="!slotProps.data.isFolder" class="container-file-info">
                    <show-link
                      :shared="slotProps.data.isShared"
                      :name="slotProps.data.key"
                      :icons="iconForCopy[slotProps.data.key]"
                      :link="`https://s3.rusonyxcloud.ru/${storageid}/${slotProps.data.key}`"
                      @set-icon="setIcon($event)"
                    />
                    <!--                  <div class="container-file-info__view__el">-->
                    <!--                    <div class="container-file-info__el">-->
                    <!--                      <label class="standart-title">Класс хранилища:</label>-->
                    <!--                      <label class="standart-text">{{ slotProps.data.class }}</label>-->
                    <!--                    </div>-->
                    <!--                    <div class="container-file-info__el">-->
                    <!--                      <label class="standart-title">Content type:</label>-->
                    <!--                      <label class="standart-text">{{ slotProps.data.type }}</label>-->
                    <!--                    </div>-->
                    <!--                    <div class="container-file-info__el">-->
                    <!--                      <label class="standart-title">ETag:</label>-->
                    <!--                      <label class="standart-text">{{ slotProps.data.eTag }}</label>-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                  </div>
                  <div v-else class="container-folder-prefix">
                    <label class="standart-title">Тип: </label>
                    <label class="standart-text">{{
                      getTypeIconForPrefix(slotProps.data.key) === 'pi pi-folder-open'
                        ? 'Директория'
                        : 'Префикс'
                    }}</label>
                    <label
                      v-if="slotProps.data.count || findObject(slotProps.data.prefix)?.count"
                      class="standart-title"
                      >Кол-во объектов:
                    </label>
                    <label
                      v-if="slotProps.data.count || findObject(slotProps.data.prefix)?.count"
                      class="standart-text"
                      >{{ slotProps.data.count || findObject(slotProps.data.prefix)?.count }}</label
                    >
                  </div>
                  <div class="buttons-context">
                    <Button
                      v-if="!slotProps.data.isFolder"
                      v-tooltip.left="'Сохранить'"
                      icon-pos="right"
                      :disabled="isDisabledButtons"
                      icon="pi pi-download"
                      class="p-button-rounded p-button-text"
                      @click="viewInfo(slotProps.data)"
                    ></Button>
                    <div v-if="!slotProps.data.isShared">
                      <transition name="fade" mode="out-in">
                        <Button
                          v-if="!slotProps.data.isFolder"
                          v-tooltip.left="'Поделиться'"
                          :disabled="isDisabledButtons"
                          icon="pi pi-share-alt"
                          icon-pos="right"
                          class="p-button-rounded p-button-text"
                          @click="createS3Link(slotProps.data.key)"
                        />
                      </transition>
                    </div>
                    <Button
                      v-if="slotProps.data.isFolder"
                      v-tooltip.left="'Загрузить объект в папку'"
                      :disabled="isDisabledButtons || selectedObjects.length > 0"
                      icon="pi pi-upload"
                      icon-pos="right"
                      class="p-button-rounded p-button-text"
                      @click="putFileInFolder(slotProps.data.key)"
                    ></Button>
                    <Button
                      icon="pi pi-trash"
                      v-tooltip.left="'Удалить'"
                      :disabled="isDisabledButtons || selectedObjects.length > 0"
                      icon-pos="right"
                      class="p-button-rounded p-button-text p-button-danger"
                      @click="deleteObject(slotProps.data.key)"
                    ></Button>
                  </div>
                </div>
                <div v-if="!slotProps.data.isFolder" class="container-file-info__view__el">
                  <div class="container-file-info__el">
                    <label class="standart-title">Класс хранилища:</label>
                    <label class="standart-text">{{ slotProps.data.class }}</label>
                  </div>
                  <div class="container-file-info__el">
                    <label class="standart-title">Content type:</label>
                    <label class="standart-text">{{ slotProps.data.type }}</label>
                  </div>
                  <div class="container-file-info__el">
                    <label class="standart-title">ETag:</label>
                    <label class="standart-text">{{ slotProps.data.eTag }}</label>
                  </div>
                </div>
              </div>
            </template>
            <template #paginatorend> </template>
            <template #paginatorstart> </template>
          </DataTable>
        </page-block>
        <router-view></router-view>
      </page-block-stack>
    </transition>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import { OPStorage } from '@/models/OP/OPStorage';
import PageBlockStack from '@/components/PageBlock/PageBlockStack';
import newFolder from '@/layouts/Stack/mixins/newFolder';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import popoverFix from '@/mixins/popoverFix';
import Column from 'primevue/column';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import { format } from 'date-fns';
import Toast from 'primevue/toast';
import deleteObjects from '@/layouts/Stack/mixins/deleteObjects';
import Vue from 'vue';
// import viewObject from '@/layouts/Stack/mixins/viewObject';
import Checkbox from 'primevue/checkbox';
import viewObject from '@/layouts/Stack/mixins/viewObject';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import createS3Link from '@/layouts/Stack/mixins/createS3Link';
import FileUpload from 'primevue/fileupload/FileUpload';
import BaseProgress from '@/components/BaseLoader/BaseProgress';
import BreadcrumdsStorage from '@/layouts/Stack/components/BreadcrumdsStorage.vue';
import showErrorModal from '@/mixins/showErrorModal';
import ShowLink from '@/layouts/Stack/components/ShowLink.vue';
import LoaderPrefixSize from '@/layouts/Stack/components/LoaderPrefixSize.vue';

export default {
  name: 'Objects',
  components: {
    ShowLink,
    LoaderPrefixSize,
    BreadcrumdsStorage,
    Checkbox,
    BaseProgress,
    DataTable,
    Column,
    PageBlockStack,
    InputText,
    Toast,
    FileUpload,
    Button,
  },
  mixins: [
    providerChange,
    newFolder,
    viewObject,
    popoverFix,
    createS3Link,
    showErrorModal,
    modals,
    deleteObjects,
  ],
  beforeRouteLeave(_to, _from, next) {
    this.collapseAll();
    next();
  },
  props: {
    storageid: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      acl: ['FULL_CONTROL', 'READ', 'WRITE', 'WRITE_ACP', 'READ_ACP'],
      checkedACL: {},
      objectsToDelete: [],
      countOfSelectedObjects: 0,
      selectedObjectsForDelete: [],
      currentObject: '',
      checkedAll: false,
      errorToDelete: false,
      enabledPrefixLoader: false,
      isDisabledButtons: false,
      uploadSelectedObjects: false,
      defaultLinkExpired: {
        d: 0,
        h: 1,
        m: 0,
        s: 0,
      },
      download: false,
      expandedRows: [],
      file: {},
      fileName: '',
      filters: {
        global: { value: null },
        uniqueName: { value: null },
        path: { value: null },
      },
      firstPage: 0,
      folders: [],
      invalidDates: null,
      isCurrentLoading: false,
      isFileUploadInFolder: false,
      isLinkCopy: {},
      isLinkCreatedObj: {},
      isMobile: true,
      isRequest: false,
      isLoadSizePrefix: false,
      linkExpired: {},
      linkTime: '01:00:00',
      loader: false,
      maxDate: null,
      minDate: null,
      mode: 'view',
      objectsACL: {},
      prefixFolderForUploadInFolder: null,
      prefixForPutFileInFolder: '',
      progress: 0,
      rowPage: 10,
      selectedObjectsACL: {},
      selectedObjects: [],
      totalSize: 100,
      type: 'determinate',
    };
  },
  computed: {
    tooltipOptions() {
      // if (!this.tooltip) return '';
      return {
        content: 'Одновременно можно удалить не более 1000 объектов',
        placement: 'right-end',
        classes: 'standart-text base-input-tooltip',
        trigget: 'hover click focus',
        container: false,
      };
    },
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
    stateForPrefixSizeAndCount() {
      return this.$store.state.moduleStack?.sizePrefix;
    },

    prefix() {
      return this.$route.query.prefix || '/';
    },
    iconForCopy() {
      return this.isLinkCopy;
    },
    objects() {
      return this.tariff.objects.filter(x => x.uniqueName);
    },
    isPublic() {
      return this.tariff.policy.IsPublic;
    },

    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    selectedObjects: {
      handler: function (event) {
        if (event.length) {
          this.checkedAll = this.$refs.objects.dataToRender.length === event.length;
        } else {
          this.countOfSelectedObjects = 0;
          this.checkedAll = false;
          this.selectedObjectsForDelete = [];
          this.mode = 'view';
        }
      },
      deep: true,
      immediate: false,
    },
    prefix(event) {
      this.clearSelectedObjects();
      if (event) this.updateStorageInfo(event);
      else this.updateStorageInfo();
    },
    isSuccessDeleted: {
      handler: async function (event) {
        if (event) {
          this.$toast.add({
            severity: 'success',
            summary: 'Объект удалён',
            detail: this.currentObject,
            life: 5000,
          });
          this.currentObject = '';
          this.isSuccessDeleted = false;
          await this.updateStorageInfo(this.prefix);
        }
      },
    },
    screenWidth: function () {
      this.setIsMobile();
    },
    progress(event) {
      if (event) {
        this.$store.state.moduleStack.progressBar = event;
      }
      if (event === this.$store.state.moduleStack.totalSize) {
        setTimeout(() => {
          this.$store.state.moduleStack.progressBar = 0;
          this.$store.state.moduleStack.totalSize = 0;
        }, 500);
      }
    },
    iconForCopy: {
      handler: function (event) {},
      deep: true,
      immediate: true,
    },
    objects: {
      handler: function (event) {
        if (event) {
          this.folders = [];
          this.folders.push({ name: '/', type: 'object' });
          event.forEach(node => {
            const name = node.prefix;
            const isFolder = node.isFolder;
            if (isFolder) {
              this.folders.push({ name: name, type: 'object' });
            }
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.collapseAll();
    this.selectedObjects = [];
    this.checkedAll = false;
    this.countOfSelectedObjects = 0;

    this.enabledPrefixLoader = false;
    this.updateStorageInfo(this.$route?.query?.prefix);
    this.firstPage = this.$route?.query?.count ? +this.$route.query.count : this.firstPage;
    this.rowPage = this.$route?.query?.object ? +this.$route.query.object : this.rowPage;
    const event = {
      first: this.firstPage,
      rows: this.rowPage,
    };
    const list = this.$refs.objects;
    list.d_rows = this.rowPage;
    list.d_first = this.firstPage;
    this.onPage(event);
  },

  beforeDestroy() {
    this.collapseAll();
  },
  methods: {
    async selectAll(state) {
      // console.log('statelectAll', state);
      this.uploadSelectedObjects = true;
      this.loading = true;
      if (!state) {
        const selectedPrefixes = [...this.selectedObjectsForDelete];
        const selectedObjects = this.selectedObjects;
        this.expandedRows = null;
        this.mode = 'edit';
        let summarySelected = this.countOfSelectedObjects;

        // const availableSelections = new Set(this.$refs?.objects?.dataToRender || []);
        const availableSelections = this.$refs?.objects?.dataToRender || [];
        // console.log('availableSelections', availableSelections);
        const selectedItems =
          availableSelections
            // .filter(value => availableSelections.has(value))
            .filter(obj => !selectedObjects.includes(obj)) || availableSelections;
        // console.log('selectedItems', selectedItems);
        const promises = selectedItems.map(async x => {
          this.selectedObjects.push(x);
          if (x.key === x.prefix) {
            const item = this.findObject(x.prefix);
            if (item) {
              summarySelected += item.count;
              selectedPrefixes.push(item.keys);
            } else {
              await this.updateStorageInfo(x.prefix, x.prefix).then(async () => {
                summarySelected += this.findObject(x.prefix).count;
                selectedPrefixes.push(this.findObject(x.prefix).keys);
              });
            }
          } else {
            summarySelected += 1;
            selectedPrefixes.push(x.key);
          }
        });

        await Promise.all(promises);
        this.selectedObjectsForDelete = Array.from(new Set(selectedPrefixes.flat()));
        this.countOfSelectedObjects = summarySelected;
      } else {
        this.clearSelectedObjects();
        this.errorToDelete = false;
      }
      this.uploadSelectedObjects = false;
      // });
    },
    async selectOne(event, key) {
      if (!event) {
        this.expandedRows = null;
        this.uploadSelectedObjects = true;
        let selectedPrefixes = this.selectedObjectsForDelete;
        let summarySelected = this.countOfSelectedObjects;
        this.mode = 'edit';
        const currentObject = key;
        if (currentObject.isFolder) {
          const item = this.findObject(currentObject.prefix);
          if (item) {
            selectedPrefixes.push(currentObject.prefix);
            summarySelected += item.count;
            selectedPrefixes.push(item.keys);
          } else {
            await this.updateStorageInfo(currentObject.prefix, currentObject.prefix).then(
              async () => {
                summarySelected += this.findObject(currentObject.prefix).count;
                selectedPrefixes.push(this.findObject(currentObject.prefix).keys);
              }
            );
          }
        } else {
          summarySelected += 1;
          selectedPrefixes.push(currentObject.key);
        }
        this.countOfSelectedObjects = summarySelected;
        this.selectedObjectsForDelete = Array.from(new Set(selectedPrefixes.flat()));
        this.uploadSelectedObjects = false;
      } else {
        // console.log('unSelect', key);
        this.uploadSelectedObjects = true;
        try {
          if (key.isFolder) {
            const isIncluded = this.stateForPrefixSizeAndCount.find(
              i => i.prefix === key.prefix && i.bucket === this.tariff.name
            );
            if (isIncluded) {
              this.countOfSelectedObjects -= isIncluded.count;
              const keysToRemove = new Set(isIncluded.keys.flat());
              this.selectedObjectsForDelete = this.selectedObjectsForDelete.filter(
                item => !keysToRemove.has(item)
              );
            }
          } else {
            this.countOfSelectedObjects -= 1;
            this.selectedObjectsForDelete = this.selectedObjectsForDelete.filter(
              item => item !== key.key
            );
          }
        } catch (error) {
          console.error('Ошибка при отмене выбора строки:', error);
        } finally {
          this.uploadSelectedObjects = false;
        }
      }
    },
    countPrefix(prefix) {
      const bucket = this.$store.state.moduleStack.sizePrefix.find(
        x => x.bucket === this.tariff.name && x.prefix === prefix
      );
      return bucket?.count ? bucket.count : null;
    },
    isRowSelected(row) {
      return this.selectedObjects.find(i => i.key === row);
    },
    clearSelectedObjects() {
      // console.log('clearSelectedObjects');
      // this.selectedObjects = Object.assign(this.selectedObjects, {});
      this.selectedObjects = [];
      this.checkedAll = false;
      this.errorToDelete = false;
      this.mode = 'view';
      this.countOfSelectedObjects = 0;
      this.selectedObjectsForDelete = [];
    },
    getTypeIconForPrefix(prefix) {
      if (this.stateForPrefixSizeAndCount.find(x => x.prefix === prefix)) {
        return this.stateForPrefixSizeAndCount.find(x => x.prefix === prefix).isPrefix
          ? 'pi pi-folder'
          : 'pi pi-folder-open';
        // return icon;
      } else return 'pi pi-folder-open';
    },
    // deleteSelectedObjects() {
    //   console.log('deleteSelectedObjects');
    //   this.clearSelectedObjects();
    // },
    // editMode() {
    //   this.$nextTick(() => (this.mode = this.mode === 'view' ? 'edit' : 'view'));

    // if (this.mode === 'edit') this.$refs.objects.selectionMode = 'multiple';
    // else this.$refs.objects.selectionMode = '';
    // },
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.id,
      });
    },
    async sendUrl(item) {
      const config = {
        region: 'ru-msk',
        endpoint: 'https://s3.rusonyxcloud.ru',
        credentials: {
          accessKeyId: this.s3key.access,
          secretAccessKey: this.s3key.secret,
        },
      };
      const client = await new S3Client(config);
      const input = {
        Bucket: this.storageid,
        Key: item,
      };
      return await this.getSignedDownloadUrl(client, input);
    },
    async getSignedDownloadUrl(client, input) {
      let command = new PutObjectCommand(input);
      return await getSignedUrl(client, command, { expiresIn: 3600 });
    },
    async updateStorageInfo(prefix, currentPrefix = null) {
      this.loader = true;
      const params = {
        storage: this.storageid,
        delimiter: '/',
      };
      if (currentPrefix) {
        delete params.delimiter;
        params.currentPrefix = currentPrefix;
      }
      if (prefix) {
        params.prefix = prefix;
      }
      try {
        await this.$store.dispatch('moduleStack/fetchCurrentStorages', params);
        await this.getS3BucketList();
      } finally {
        this.loader = false;
      }
    },
    view(event) {
      // console.log('before---------------------------------upload', event);
    },
    onPage(event) {
      // console.log('---onPage---', event);
      this.rowPage = event.rows;
      this.firstPage = event.first;
      this.clearSelectedObjects();
      this.$router
        .push({
          name: 'containerObjects',
          query: {
            object: event.rows,
            count: event.first,
            prefix: this.prefix ? this.prefix : '',
          },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showError(err);
          }
        });
    },
    onSort() {
      this.clearSelectedObjects();
      this.$router
        .push({
          name: 'containerObjects',
          query: {
            object: this.rowPage,
            count: 0,
            prefix: this.prefix ? this.prefix : '',
          },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showError(err);
          }
        });
    },
    clearFile() {
      this.fileName = '';
    },
    async select(event) {
      this.file = event.files[0];
      this.totalSize = this.file.size;
      this.fileName = event && event.files && event.files.length ? event.files[0].name : '';
      if (this.prefixFolderForUploadInFolder) {
        this.fileName = this.prefixFolderForUploadInFolder + this.fileName;
        await this.myUploader();
      }
      let findNameInArray = !!this.objects.find(x => x.name === this.fileName);
      if (findNameInArray) {
        this.showResModal(
          'Объект с таким именем уже существует. <br> При загрузке файл обновит текущий объект.'
        );
      }
    },
    progressLoad(event) {},
    size(item) {
      if (item && item.size) return objectSizeParser(item.size);
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        // centered: true,
        confirm: {
          props: { title: this.$t('confirm') },
          on: {
            click: async () => {
              this.$modals.close();
              if (this.prefixFolderForUploadInFolder) await this.myUploader();
            },
          },
        },
        cancel: {
          props: {
            title: 'Отменить',
            loading: false,
          },
          on: {
            click: () => {
              this.$modals.close();
              this.$refs.upload.clear();
            },
          },
        },
      });
    },
    async myUploader(event) {
      this.loader = true;
      this.$store.state.moduleStack.totalSize = 0;
      this.$store.state.moduleStack.progressBar = 0;
      const prefix = event
        ? this.prefix.startsWith('/')
          ? this.prefix.slice(1)
          : this.prefix
        : this.prefixFolderForUploadInFolder;

      let formData = new FormData();
      this.$store.state.moduleStack.totalSize = this.totalSize;
      const nameWithPrefix = event
        ? prefix
          ? prefix + this.fileName
          : this.fileName
        : this.fileName;
      formData.append(nameWithPrefix, this.file);
      const url = await this.sendUrl(nameWithPrefix);
      const params = {
        url: url,
        storage: this.storageid,
        file: this.file,
        // name: nameWithPrefix,
        name: this.fileName,
      };
      // console.log(params);
      await this.onUpload(params)
        .then(async data => {
          if (data === 200) {
            if (this.prefixFolderForUploadInFolder)
              await this.$refs.prefixSize.onChange(this.prefixFolderForUploadInFolder);
            this.$toast.add({
              severity: 'success',
              summary: 'Объект загружен',
              detail: this.file.name,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Ошибка',
              detail: this.file.name,
              life: 3000,
            });
          }
        })
        .finally(() => {
          // console.log('finally');
          this.isFileUploadInFolder = false;
          this.progress = 0;
          this.fileName = '';
          // await this.$refs.prefixSize.

          this.prefixFolderForUploadInFolder = null;
          this.file = [];
          if (this.$refs?.upload) this.$refs.upload.clear();
          this.collapseAll();
          this.loader = false;
        });
    },
    findObject(prefix) {
      return this.stateForPrefixSizeAndCount.find(
        i => i.prefix === prefix && i.bucket === this.tariff.name
      );
    },
    // async onRowSelect(event) {
    //   if (event.type === 'checkbox') {
    //     this.expandedRows = null;
    //     this.uploadSelectedObjects = true;
    //
    //     let selectedPrefixes = [...this.selectedObjectsForDelete];
    //     let summarySelected = this.countOfSelectedObjects;
    //     this.mode = 'edit';
    //
    //     const currentObject = event.data;
    //
    //     try {
    //       if (currentObject.isFolder) {
    //         let item = this.findObject(currentObject.prefix);
    //
    //         if (!item) {
    //           await this.updateStorageInfo(currentObject.prefix, currentObject.prefix);
    //           item = this.findObject(currentObject.prefix);
    //         }
    //
    //         if (item) {
    //           try {
    //             selectedPrefixes.push(currentObject.prefix, ...item.keys.flat());
    //             summarySelected += item.count;
    //           } catch (e) {
    //             this.errorToDelete = true;
    //           }
    //         }
    //       } else {
    //         summarySelected += 1;
    //         selectedPrefixes.push(currentObject.key);
    //       }
    //
    //       this.countOfSelectedObjects = summarySelected;
    //       this.selectedObjectsForDelete = Array.from(new Set(selectedPrefixes.flat()));
    //     } catch (error) {
    //       console.error('Ошибка при выборе строки:', error);
    //     } finally {
    //       this.uploadSelectedObjects = false;
    //     }
    //   }
    // },
    // async onRowSelect(event) {
    //   if (event.type === 'checkbox') {
    //     this.expandedRows = null;
    //     this.uploadSelectedObjects = true;
    //     let selectedPrefixes = this.selectedObjectsForDelete;
    //     let summarySelected = this.countOfSelectedObjects;
    //     this.mode = 'edit';
    //     const currentObject = event.data;
    //     if (currentObject.isFolder) {
    //       const item = this.findObject(currentObject.prefix);
    //       if (item) {
    //         selectedPrefixes.push(currentObject.prefix);
    //         summarySelected += item.count;
    //         selectedPrefixes.push(item.keys);
    //       } else {
    //         await this.updateStorageInfo(currentObject.prefix, currentObject.prefix).then(
    //           async () => {
    //             summarySelected += this.findObject(currentObject.prefix).count;
    //             selectedPrefixes.push(this.findObject(currentObject.prefix).keys);
    //           }
    //         );
    //       }
    //     } else {
    //       summarySelected += 1;
    //       selectedPrefixes.push(currentObject.key);
    //     }
    //     this.countOfSelectedObjects = summarySelected;
    //     this.selectedObjectsForDelete = Array.from(new Set(selectedPrefixes.flat()));
    //     this.uploadSelectedObjects = false;
    //   }
    // },
    // async onRowSelectAll(event) {
    //   this.uploadSelectedObjects = true;
    //   this.loading = true;
    //   console.log(event);
    //   // this.$nextTick(async () => {
    //   console.log('onRowSelectAll', event);
    //   this.uploadSelectedObjects = true;
    //   const selectedPrefixes = [...this.selectedObjectsForDelete];
    //   const selectedObjects = this.selectedObjects;
    //   this.expandedRows = null;
    //   this.mode = 'edit';
    //   let summarySelected = this.countOfSelectedObjects;
    //
    //   const availableSelections = new Set(this.$refs?.objects?.dataToRender || []);
    //   const selectedItems = event.data
    //     .filter(value => availableSelections.has(value))
    //     .filter(obj => !selectedObjects.includes(obj));
    //
    //   const promises = selectedItems.map(async x => {
    //     if (x.key === x.prefix) {
    //       const item = this.findObject(x.prefix);
    //       if (item) {
    //         summarySelected += item.count;
    //         selectedPrefixes.push(item.keys);
    //       } else {
    //         await this.updateStorageInfo(x.prefix, x.prefix).then(async () => {
    //           summarySelected += this.findObject(x.prefix).count;
    //           selectedPrefixes.push(this.findObject(x.prefix).keys);
    //         });
    //       }
    //     } else {
    //       summarySelected += 1;
    //       selectedPrefixes.push(x.key);
    //     }
    //   });
    //
    //   await Promise.all(promises);
    //   this.selectedObjectsForDelete = Array.from(new Set(selectedPrefixes.flat()));
    //   this.countOfSelectedObjects = summarySelected;
    //   this.uploadSelectedObjects = false;
    //   // });
    // },
    // onRowUnselectAll(event) {
    //   this.errorToDelete = false;
    //   this.clearSelectedObjects();
    //   // console.log('onRowUnselectAll', event);
    // },
    // onRowUnselect(event) {
    //   this.uploadSelectedObjects = true;
    //   try {
    //     if (event.data.isFolder) {
    //       const isIncluded = this.stateForPrefixSizeAndCount.find(
    //         i => i.prefix === event.data.prefix && i.bucket === this.tariff.name
    //       );
    //       if (isIncluded) {
    //         this.countOfSelectedObjects -= isIncluded.count;
    //         const keysToRemove = new Set(isIncluded.keys.flat());
    //         this.selectedObjectsForDelete = this.selectedObjectsForDelete.filter(
    //           item => !keysToRemove.has(item)
    //         );
    //       }
    //     } else {
    //       this.countOfSelectedObjects -= 1;
    //       this.selectedObjectsForDelete = this.selectedObjectsForDelete.filter(
    //         item => item !== event.data.key
    //       );
    //     }
    //   } catch (error) {
    //     console.error('Ошибка при отмене выбора строки:', error);
    //   } finally {
    //     this.uploadSelectedObjects = false;
    //   }
    // },
    // onRowUnselect(event) {
    //   this.uploadSelectedObjects = true;
    //   if (event.data.isFolder) {
    //     const isIncluded = this.stateForPrefixSizeAndCount.find(
    //       i => i.prefix === event.data.prefix && i.bucket === this.tariff.name
    //     );
    //     this.countOfSelectedObjects -= isIncluded.count;
    //     this.selectedObjectsForDelete = this.selectedObjectsForDelete.filter(
    //       item => !Array.from(new Set(isIncluded.keys.flat())).includes(item)
    //     );
    //   } else {
    //     this.countOfSelectedObjects -= 1;
    //     this.selectedObjectsForDelete = this.selectedObjectsForDelete.filter(
    //       item => item !== event.data.key
    //     );
    //   }
    //   this.uploadSelectedObjects = false;
    // },
    created(i) {
      if (i && i.date) return format(i.date, 'dd.MM.yyyy, HH:mm');
    },
    async deleteObject(item, keys = []) {
      const params = {
        storage: this.tariff,
        name: item,
        id: this.id,
      };
      if (keys.length) params.keys = [...keys];
      this.currentObject = item;
      return await this.deleteObjects(params)
        // return await this.deleteObjects(this.tariff, item)
        .catch(e => {
          const err = {
            statusCode: e.statusCode || 400,
            body: e.message || JSON.stringify(e.message),
          };
          const msgError =
            err.body === 'AccessDenied'
              ? 'Недостаточно прав доступа для выполнения операции.'
              : err.body;
          if (!this.modal) this.makeModal();
          Vue.set(this.modal, 'component', null);
          Vue.set(this.modal, 'closable', true);
          Vue.set(this.modal, 'text', msgError);
          Vue.set(this.modal, 'footer', {
            centered: true,
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  this.$modals.close();
                },
              },
            },
          });
          if (this.modal.footer.confirm) {
            Vue.set(this.modal.footer, 'cancel', false);
          }
        })
        .finally(async () => {
          await this.updateStorageInfo(this.prefix);
          this.clearSelectedObjects();
        });
    },
    // copyLink(item) {
    //   const url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item}`;
    //   this.$clipboard(url);
    //   this.showSuccessModal('Ссылка для скачивания успешно скопирована в буфер обмена.');
    // },
    // copyCurrentLink(item) {
    //   const url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item}`;
    //   this.$nextTick(() => {
    //     setTimeout(() => (this.isLinkCopy[item] = 'loading'), 100);
    //     this.$clipboard(url);
    //     setTimeout(() => (this.isLinkCopy[item] = 'checked'), 300);
    //   });
    //   // this.$nextTick(() => (this.isLinkCopy[item] = 'checked'));
    //   // this.isLinkCopy[item] = true;
    // },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'medium',
        text,
      });
    },
    async onRowExpand(event) {
      // console.log(event);
      const id = event?.data?.key ? event?.data?.key : event;
      this.isLinkCopy = {};
      this.isLinkCopy[id] = 'copy';
      if (event?.data?.isFolder) {
        this.prefixForPutFileInFolder = event.data.prefix;
      }
      // console.log('onRowExpand', this.isLinkCopy[id]);
      await this.getObjectHead(id);
      if (!event?.data?.isPrefix) {
        const acl = await this.getObjectACL(id); //.catch(err => 'err');
      }
    },
    onRowCollapse(event) {},
    expandAll() {
      const list = this.$refs.objects;
      list.d_rows = 25;
      list.d_first = 75;
    },
    collapseAll() {
      this.expandedRows = null;
      this.clearSelectedObjects();
      this.enabledPrefixLoader = false;
    },
    async getObjectACL(item) {
      const params = {
        bucket: this.storageid,
        name: item,
        key: this.s3key,
      };
      return await this.$store.dispatch('moduleStack/getObjectACL', params);
    },
    async getObjectHead(item) {
      const params = {
        bucket: this.storageid,
        name: item,
        key: this.s3key,
      };
      // console.log(params);
      return await this.$store.dispatch('moduleStack/getObjectHead', params);
    },
    async putFileInFolder(item) {
      // console.log(item);
      this.isFileUploadInFolder = true;
      this.prefixFolderForUploadInFolder = item;
      // console.log(this.$refs.upload);
      this.$refs.upload?.onBasicUploaderClick();
      // this.$refs.upload.onFileSelect();
      // console.log(this.$refs.upload);
      // console.log(this.$refs.upload.isFileSelected());
    },
    async viewInfo(item) {
      let url;
      if (item.isShared) {
        url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item.key}`;
      } else {
        const config = {
          region: 'ru-msk',
          endpoint: 'https://s3.rusonyxcloud.ru',
          credentials: {
            accessKeyId: this.s3key.access,
            secretAccessKey: this.s3key.secret,
          },
        };
        const client = await new S3Client(config);

        const input = {
          Bucket: this.storageid,
          Key: item.key,
        };

        async function getSignedDownloadUrl() {
          let command = new GetObjectCommand(input);
          return await getSignedUrl(client, command, { expiresIn: 3600 });
        }

        url = await getSignedDownloadUrl();
      }
      window.open(url, '_blanc');
      // const name = item.key.includes('/') ? item.key.split('/').at(-1) : item.key;
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', props);
      Vue.set(this.modal, 'title', props);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    onStorageClick() {
      if (this.prefix.length > 1) {
        this.collapseAll();

        this.$router.push({
          name: 'containerObjects',
          query: {
            object: this.rowPage,
            count: 0,
          },
        });
      }
    },
    onFolderClick: function (folder) {
      // console.log('onFolderClick', folder);
      this.collapseAll();
      this.$router.push({
        name: 'containerObjects',
        query: {
          prefix: folder,
          object: this.rowPage,
          count: 0,
        },
      });
    },
    setIcon(event) {
      // console.log(event);
      this.isLinkCopy = {};
      this.isLinkCopy[event.name] = event.icon;
      // console.log(this.isLinkCopy[event.name]);
      // console.log(this.isLinkCopy);
    },
    disablePrefixLoader(event) {
      if (!event) {
        this.enabledPrefixLoader = false;
        this.isDisabledButtons = false;
        this.loader = false;
      } else {
        this.enabledPrefixLoader = true;
        this.isDisabledButtons = true;
        this.loader = true;
      }
    },
    countObjectForPrefix(prefix) {
      // sizePrefix() {
      const bucket = this.$store.state.moduleStack.sizePrefix.find(
        x => x.bucket === this.tariff.name && x.prefix === prefix
      );
      return bucket?.count > 0 ? bucket?.count : null;
      // },
    },
    loadPrefixSize(event) {
      // console.log('click ', event);
    },
    // showIconCopy(item) {
    //   console.log(item);
    //   console.log(this.isLinkCopy[item] !== 'noclick' ? 'accept' : 'copy-gray');
    //   return this.isLinkCopy[item] !== 'noclick' ? 'accept' : 'copy-gray';
    // },
    //
    async onUpload(payload) {
      const params = {
        onUploadProgress: progressEvent => {
          this.progress = progressEvent.loaded;
        },
        ...payload,
      };
      return this.$store
        .dispatch('moduleStack/sendFile', params)
        .then(async data => {
          // console.log(data);
          await this.updateStorageInfo(this.prefix);
          // await this.getS3BucketList();
          return data;
        })
        .catch(e => {
          console.log(e);
          let error;
          error =
            e.response.data.Code === 'QuotaExceeded'
              ? 'Превышена квота по количеству объектов. Пожалуйста увеличьте квоту или обратитесь в техподдержку.'
              : 'Произошла ошибка, попробуйте повторить позднее, либо обратитесь в техподдержку';
          this.showError(error);
          return 'error';
        });
    },
    async updateStorage() {
      await this.getCurrentStorage();
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addFolder": "Добавить префикс",
      "add_file": "Загрузить файлы",
      "object": " Выделено 0 объектов | Выделен {n} объект | Выделены {n} объекта | Выделено {n} объектов",
      "objects": " (0 объектов) | ({n} объект) | ({n} объекта) | ({n} объектов)",
      "buffer": "0 объектов | {n} объект | {n} объекта | {n} объектов",
      "new": "Создать папку",
      "select": "Выбрать",
      "FULL_CONTROL": "Полный доступ ",
      "READ": "Чтение ",
      "READ_ACP": "Чтение ACL ",
      "sure": {
        "create": "Подтвердить",
        "successLink": "Ссылка сгенерирована и скопирована в буфер обмена."
      },
      "WRITE": "Запись ",
      "WRITE_ACP": "Запись ACL ",
      "delete": "Удалить объекты",
      "deselect": "Очистить",
      "tableHead": {
        "name": "Файл",
        "size": "Размер",
        "date": "Дата создания",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
:deep(.p-tooltip-text) {
  font-family: $font-base;
  font-size: 12px;
  //color: #333;
}

.action-buttons {
  display: flex;
  //flex-direction: column
}

.container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.container-file {
    display flex
    justify-content: space-between
    align-items: baseline
    flex-direction: column;

    +breakpoint(xs-only) {
      flex-direction: column
    }

  &-info{
    width: 80%
    display: flex;
    align-items: baseline
    justify-content: space-between
    flex-direction: column;
    gap: 0.75rem;

  +breakpoint(ml-and-down) {
    flex-direction: column
  }
    &__header {

    }
  &__view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
    width: 100%;
    &__el {
      display: flex;
      width: 100%
      flex-direction: row;
      align-content: flex-start;
      justify-content: space-between;
      align-items: baseline;


      & + & {
        margin-bottom: 0.5rem
        margin-top: 0.5rem
      }

    }
  }
    &__el {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
      gap: 0.25rem;

      & + & {
        margin-bottom: 0.5rem
        margin-top: 0.5rem
      }
    }
  &__permission {
      //margin: auto 0.5rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
  }
  }
}

.container-folder {
  display: flex;
  justify-content: space-between;
  align-items: center
  flex-direction: row;

  +breakpoint(xs-only) {
    flex-direction: column
  }
  &-prefix {
    display: flex;
    justify-content: flex-start;
    align-items: center
    flex-direction: row;
    gap: 1rem;
  }

  &-info{
    width: 80%
    display: flex;
    align-items: baseline
    justify-content: space-between
    flex-direction: row;

    +breakpoint(ml-and-down) {
      flex-direction column
    }

    &__el {
      //margin: auto 1rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;

      & + & {
        margin-bottom: 0.5rem
        margin-top: 0.5rem
      }

    }
    &__permission {
      //margin: auto 0.5rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
    }
  }
}

.time {
  width: 10rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem

  &__select {
    width: 100%;
    margin: 1rem 0.65rem 0.5rem 0.65rem;

    //margin: auto 1rem auto 0.65rem;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    &-selected {
      margin-left: 1rem;
    }

    &-buttons {

      &__btn {
        margin: auto 0.5rem;
      }
    }

  }
}
.field-checkbox{
  display flex
}
.folder-link {
  position: relative;
  width: 100%
  color: $primary-color;
  text-decoration: none;
  white-space: nowrap;
  //background: var(--main-card-bg);
}
.time-buttons {
  width: 3rem
  min-width: 3rem;
}
.header-bottom{
  display flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: flex-start;


  &__start {
    margin auto 1rem
    min-width: 10rem
  }
  &__second {
    margin: auto 1rem
    min-width: 10rem
    max-width: 15rem
    display flex
    flex-direction: row;

  }
  &__progress {
    margin: auto 1rem
    width: 100%
    text-align: end;
  }
}
.buttons-context{
    display flex;
    //flex-direction column;
    justify-content flex-end;
  align-items: flex-end;
  }
.p-datatable-header-up{
  background: #fff;
  border: 0;
}
.datatable-footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1rem;
}
.filter{
  display: flex;
  justify-content: space-between;
  //justify-content: flex-end;
  margin-bottom: 0.75rem;
      flex-wrap:wrap;
  align-items: center;
    +breakpoint(ms-and-down) {
    }
  &-upload{
    margin-right: 2rem
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    //width: 10%
    //order: 1;
    +breakpoint(ml-and-down) {
    order: 1;
    }
    &__btn{
      margin-left: 1rem;

    }
    & + & {
      margin-left: 2rem
    }

  }
  &-progress {
    margin-right: 2rem;
    flex-grow: 2
    //width: 50%
    max-width: 700px
    //order: 2;
    //+breakpoint(ml-only) {
    //  margin-top: 0.5rem;
    //  width: 100%
    //  order: 3;
    //}
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      width: 100%
      order: 3;
    }
  }

  &-search{
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      order: 2;
    }
    //+breakpoint(ms-and-down) {
    //  order: 3;
      //margin-top: 0.5rem;
      //order: 2;
      //width: 45%
    //}
  }
  & + & {
    margin-left: 2rem
  }
  }
.p-inputnumber-input{
  min-width: 4rem
  text-align center
}
</style>
